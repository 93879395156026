var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "single-answer" },
    [
      _c(
        "h2",
        { class: ["question-title", _vm.body.header ? "with-subtext" : ""] },
        [_vm._v(" " + _vm._s(_vm.body.question) + " ")]
      ),
      _vm.body.header
        ? _c("p", { staticClass: "sub-text group-mb" }, [
            _vm._v(_vm._s(_vm.body.header)),
          ])
        : _vm._e(),
      _vm.body.description
        ? _c("p", { staticClass: "sub-text group-mb" }, [
            _vm._v(" " + _vm._s(_vm.body.description) + " "),
          ])
        : _vm._e(),
      _vm._l(_vm.body.answerlist, function (list, i) {
        return _c(
          "w-checkbox",
          {
            key: list.name + i,
            class: list.name == "No Preference" ? "no-option" : "",
            attrs: {
              type: "radio",
              id: list.name + _vm.body.id,
              value: list.id,
              label: list.name,
              disabled: _vm.disabled,
              description: list.description,
              recommended: list.mostpopular || list.bestdeal,
              "recommended-text": list.mostpopular
                ? "Most Popular"
                : list.bestdeal
                ? "Best Deal"
                : "",
              treatment: list.tag == "treatmentform",
              "treatment-text": list.pricingstatement,
              bold: list.tag == "treatmentform" ? true : false,
            },
            on: {
              change: function ($event) {
                return _vm.changeAnswer(_vm.answer, list)
              },
            },
            scopedSlots: _vm._u(
              [
                list.image
                  ? {
                      key: "image",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "img-holder" }, [
                            _c("img", {
                              attrs: { src: list.image, alt: list.name },
                            }),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.answer,
              callback: function ($$v) {
                _vm.answer = $$v
              },
              expression: "answer",
            },
          },
          [
            list.discountedprice
              ? [
                  _c("ul", { staticClass: "list-unstyled mb-0 mt-3" }, [
                    _c("li", { staticClass: "text-muted small-text" }, [
                      _c("s", [_vm._v(_vm._s(list.regularprice))]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "mb-0 small-text bold-font" }, [
                        _vm._v(_vm._s(list.discountedprice)),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
            list.hasOwnProperty("regularprice") &&
            !list.hasOwnProperty("discountedprice")
              ? [
                  _c("span", { staticClass: "mb-0 mt-3" }, [
                    _vm._v(_vm._s(list.regularprice)),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      this.showContinue
        ? _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.disabled,
                pill: "",
                block: "",
                variant: "info",
              },
              on: {
                click: function ($event) {
                  return _vm.checkAnswer(_vm.answer)
                },
              },
            },
            [_vm._v("Continue")]
          )
        : _vm._e(),
      _vm.body.footerarray
        ? _c(
            "ul",
            { staticClass: "mt-5" },
            _vm._l(_vm.body.footerarray, function (item, i) {
              return _c(
                "li",
                { key: `fruit-${i}`, staticClass: "sub-text group-mb mt-3" },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }