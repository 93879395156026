var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "nothing", attrs: { "align-v": "stretch" } },
    [
      _c(
        "b-col",
        { attrs: { "align-self": "center" } },
        [
          _c("h2", { staticClass: "question-title with-subtext" }, [
            _vm._v(" " + _vm._s(_vm.body.title) + " "),
          ]),
          _vm.body.description
            ? _c("p", { staticClass: "sub-text mb-0" }, [
                _vm._v(" " + _vm._s(_vm.body.description) + " "),
              ])
            : _vm._e(),
          _vm.body.image
            ? _c("div", { staticClass: "image-holder" }, [
                _c("img", { attrs: { src: _vm.body.image, alt: "" } }),
              ])
            : _vm._e(),
          _c(
            "b-button",
            {
              staticStyle: { "margin-top": "1.6875rem" },
              attrs: { pill: "", variant: "info", disabled: _vm.disabled },
              on: { click: _vm.submitAnswer },
            },
            [_vm._v("Continue")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }