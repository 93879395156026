<template>
  <div class="bmi">
    <h2 class="question-title">What is your height and weight?</h2>
    <b-row>
      <b-col>
        <label for="height" class="label-text">How tall are you?</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" class="mb-3">
        <b-form-input
          type="tel"
          class="input-bg flat fs-mask bmi-input"
          label="height"
          placeholder="Feet"
          v-model="height.feet"
          :state="feetState"
          :formatter="numberOnly"
        />
        <b-form-invalid-feedback id="bmi-feet">
          Input must be numbers only.
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-input
          id="bmi-inch"
          type="tel"
          class="input-bg flat fs-mask bmi-input"
          label="height"
          placeholder="Inches"
          v-model="height.inch"
          :state="inchState"
          :formatter="numberOnly"
        />
        <b-form-invalid-feedback id="bmi-inch">
          Input must be numbers only.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12" md="6">
        <label for="weight" class="label-text">What's your weight?</label>
        <b-form-input
          id="bmi-weight"
          class="input-bg flat fs-mask bmi-input"
          type="tel"
          label="weight"
          placeholder="Pounds"
          v-model="weight"
          :state="weightState"
          :formatter="numberOnly"
        />
        <b-form-invalid-feedback id="bmi-weight">
          Input must be numbers only.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-button
      pill
      block
      variant="info"
      class="mt-4"
      @click="submitAnswer"
      :disabled="disabled"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    bmi: {
      type: Object,
      default() {
        return {
          height: {
            feet: "",
            inch: "",
          },
          weight: "",
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userid: {
      type: String,
      default: "",
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      height: {
        feet: "",
        inch: "",
      },
      weight: "",
    };
  },
  computed: {
    feetState() {
      return !!this.height.feet || this.height.feet.length == 0;
    },
    inchState() {
      return (
        !!this.height.inch ||
        this.height.inch.length == 0 ||
        this.height.inch == 0
      );
    },
    weightState() {
      return !!this.weight || this.weight.length == 0;
    },
  },
  methods: {
    submitAnswer() {
      if (
        !this.height.feet ||
        this.height.inch.toString().replace(/ /gi, "") == "" ||
        (!this.height.inch && this.height.inch != 0) ||
        !this.weight
      ) {
        this.$emit("alert", "Please fill out all the fields.");
        return;
      } else if (this.height.feet < 3) {
        this.$emit("alert", "Feet should not be less than 3");
        return;
      } else if (this.height.feet > 8) {
        this.$emit("alert", "Feet should not be greater than 8");
        return;
      } else if (this.height.inch > 11) {
        this.$emit("alert", "Inch should not be greater than 11");
        return;
      } else if (this.height.inch < 0) {
        this.$emit("alert", "Inch should not be less than 0");
        return;
      } else if (this.weight < 50) {
        this.$emit("alert", "Weight should not be less than 50");
        return;
      } else if (this.weight > 400) {
        this.$emit("alert", "Weight should not be greater than 400");
        return;
      }
      this.$emit("submitAnswer", { height: this.height, weight: this.weight });
    },
    // Formatter
    numberOnly(value) {
      let removedNums = value.replace(/\D/g, "");
      if (removedNums.startsWith("0")) {
        removedNums = "0";
      }
      return removedNums;
    },
  },
  async mounted() {
    if (this.bmi.height.feet) {
      this.height.feet = this.bmi.height.feet;
    }
    if (this.bmi.height.inch || this.bmi.height.inch == 0) {
      this.height.inch = this.bmi.height.inch;
    }
    if (this.bmi.weight) {
      this.weight = this.bmi.weight;
    }

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.userid,
        screen: "2.99-A",
        onboardingflow: this.onboardingflow,
        firstname: this.firstname,
        lastname: this.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        ...(await this.$store.dispatch("getVoluumDetails")),
      },
      {
        referrer: await this.$store.dispatch("getEverflowDetails"),
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.form-control[type="number"],
.form-control[type="text"],
.bmi-input {
  border: 2px solid #e0e0e0;
  border-radius: 30px;

  &:focus {
    border-color: $info;
  }
}
</style>
