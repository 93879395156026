var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bmi" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v("What is your height and weight?"),
      ]),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "label",
              { staticClass: "label-text", attrs: { for: "height" } },
              [_vm._v("How tall are you?")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
            [
              _c("b-form-input", {
                staticClass: "input-bg flat fs-mask bmi-input",
                attrs: {
                  type: "tel",
                  label: "height",
                  placeholder: "Feet",
                  state: _vm.feetState,
                  formatter: _vm.numberOnly,
                },
                model: {
                  value: _vm.height.feet,
                  callback: function ($$v) {
                    _vm.$set(_vm.height, "feet", $$v)
                  },
                  expression: "height.feet",
                },
              }),
              _c("b-form-invalid-feedback", { attrs: { id: "bmi-feet" } }, [
                _vm._v(" Input must be numbers only. "),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("b-form-input", {
                staticClass: "input-bg flat fs-mask bmi-input",
                attrs: {
                  id: "bmi-inch",
                  type: "tel",
                  label: "height",
                  placeholder: "Inches",
                  state: _vm.inchState,
                  formatter: _vm.numberOnly,
                },
                model: {
                  value: _vm.height.inch,
                  callback: function ($$v) {
                    _vm.$set(_vm.height, "inch", $$v)
                  },
                  expression: "height.inch",
                },
              }),
              _c("b-form-invalid-feedback", { attrs: { id: "bmi-inch" } }, [
                _vm._v(" Input must be numbers only. "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "label",
                { staticClass: "label-text", attrs: { for: "weight" } },
                [_vm._v("What's your weight?")]
              ),
              _c("b-form-input", {
                staticClass: "input-bg flat fs-mask bmi-input",
                attrs: {
                  id: "bmi-weight",
                  type: "tel",
                  label: "weight",
                  placeholder: "Pounds",
                  state: _vm.weightState,
                  formatter: _vm.numberOnly,
                },
                model: {
                  value: _vm.weight,
                  callback: function ($$v) {
                    _vm.weight = $$v
                  },
                  expression: "weight",
                },
              }),
              _c("b-form-invalid-feedback", { attrs: { id: "bmi-weight" } }, [
                _vm._v(" Input must be numbers only. "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "mt-4",
          attrs: {
            pill: "",
            block: "",
            variant: "info",
            disabled: _vm.disabled,
          },
          on: { click: _vm.submitAnswer },
        },
        [_vm._v("Continue")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }