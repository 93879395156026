<template>
  <b-row class="pl-3">
    <h2 class="question-title">What symptoms would you like to treat?</h2>

    <b-alert :show="alert.show" :variant="alert.type">
      {{ alert.message }}
    </b-alert>

    <b-col cols="12" class="fs-exclude mb-2 pl-0 pr-0">
      <div class="symptoms" v-if="isLoading">
        <BSkeleton v-for="i in 15" :key="i" class="tw-inline tw-gap-4" />
      </div>

      <ul class="list-unstyled symptoms" v-else>
        <li v-for="(symptom, i) in symptoms" :key="i">
          <input
            type="checkbox"
            :id="'checkbox-' + i"
            v-model="selected"
            :value="symptom.id"
          />

          <label :for="'checkbox-' + i">
            <SymptomsLabel :symptom="symptom" />
            <div class="symptoms-icon" v-if="0">
              <img
                :src="preloadedIcons[symptom.id]"
                :alt="symptom.name"
                class="symptoms-icon__img"
              />

              <span class="symptoms-icon__name">
                {{ symptom.name }}
              </span>
            </div>
          </label>
        </li>
      </ul>
    </b-col>

    <b-col cols="12" class="pl-0">
      <b-button
        pill
        variant="info"
        class="btn mb-3"
        :disabled="disabled"
        @click="submitSymptoms"
      >
        Continue
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { LookUpService } from "@/services/lookup.service";
import { OnboardingService } from "@/services/onboarding.service";

// mixins
import analyticsMixin from "@/mixins/analytics";

// components
import SymptomsLabel from "./SymptomsLabel.vue";

export default {
  mixins: [analyticsMixin],
  components: {
    SymptomsLabel,
  },
  props: {
    selectedSymptoms: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    userid: {
      type: String,
      default: "",
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alert: {
        show: false,
        type: "",
        message: "",
      },
      symptoms: [],
      selected: [],
      hasOthers: false,
      preloadedIcons: {},
      isLoading: false,
    };
  },
  methods: {
    async populateSymptoms() {
      if (this.symptoms.length === 0) {
        const symptomData = await LookUpService.getLookUp("symptom");
        this.symptoms = symptomData.data;
      }
    },
    async populateSelectedSymptoms() {
      const { data } = await OnboardingService.getPatientOnboarding();
      if (data.hasOwnProperty("symptomid")) {
        this.selected = data.symptomid;
      } else if ($cookies.get("winona-quiz-v2-answers")) {
        const quizanswers = $cookies.get("winona-quiz-v2-answers");
        // Remove "other" symptom to prevent showing the alternate question
        this.selected = quizanswers.selectedSymptoms.filter(
          (e) => e !== "other"
        );
      } else {
        this.selected = [];
      }
    },
    async submitSymptoms() {
      this.selected = this.selected.filter((item) => item);
      this.$emit("submitSymptoms", this.selected);
    },
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.populateSymptoms(),
      this.populateSelectedSymptoms(),
    ]);
    this.isLoading = false;
    this.$_analytics_track("Onboarding Screen Viewed", {
      customerId: this.userid,
      screen: "2.0-A",
      onboardingflow: this.onboardingflow,
      firstname: this.firstname,
      lastname: this.lastname,
    });
  },
};
</script>
