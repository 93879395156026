var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Array", {
    ref: "arrayComponent",
    attrs: { body: _vm.questionBody, disabled: _vm.disabled },
    on: { submitAnswer: _vm.submitAnswer, alert: _vm.showAlert },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }