<template>
  <div class="symptoms-icon">
    <img :src="src" :alt="symptom.name" class="symptoms-icon__img" />

    <span class="symptoms-icon__name">
      {{ symptom.name }}
    </span>
  </div>
</template>

<script>
import IconAnxiety from "@/assets/images/symptoms/anxiety.svg";
import IconBrain from "@/assets/images/symptoms/brain.svg";
import IconEnergy from "@/assets/images/symptoms/energy.svg";
import IconFatigue from "@/assets/images/symptoms/fatigue.svg";
import IconHair from "@/assets/images/symptoms/hair.svg";
import IconHotFlashes from "@/assets/images/symptoms/hot-flashes.svg";
import IconLosingMuscle from "@/assets/images/symptoms/losing-muscle.svg";
import IconMood from "@/assets/images/symptoms/mood.svg";
import IconNightSweats from "@/assets/images/symptoms/night-sweats.svg";
import IconSexAndLibido from "@/assets/images/symptoms/sex-and-libido.svg";
import IconSkin from "@/assets/images/symptoms/skin.svg";
import IconSleep from "@/assets/images/symptoms/sleep.svg";
import IconUrinary from "@/assets/images/symptoms/urinary.svg";
import IconVaginalDryness from "@/assets/images/symptoms/vaginal-dryness.svg";
import IconWeightGain from "@/assets/images/symptoms/weight-gain.svg";

export default {
  props: {
    symptom: {
      type: Object,
      required: true,
    },
  },

  computed: {
    src() {
      switch (this.symptom.id) {
        case "anxiety":
          return IconAnxiety;
        case "brain":
          return IconBrain;
        case "energy":
          return IconEnergy;
        case "fatigue":
          return IconFatigue;
        case "hair":
          return IconHair;
        case "hotflashes":
          return IconHotFlashes;
        case "losingmuscle":
          return IconLosingMuscle;
        case "mood":
          return IconMood;
        case "nightsweats":
          return IconNightSweats;
        case "sexandlibido":
          return IconSexAndLibido;
        case "skin":
          return IconSkin;
        case "sleep":
          return IconSleep;
        case "urinary":
          return IconUrinary;
        case "vaginaldryness":
          return IconVaginalDryness;
        case "weightgain":
          return IconWeightGain;
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.symptoms-icon {
  position: relative;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 35px;
    height: 35px;
  }

  &__name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
    text-align: center;
    font-size: 12px;
    color: #19191c;
  }
}
</style>
