<template>
  <div class="multiple-answer">
    <h2 class="question-title">
      {{ body.question }}
    </h2>
    <p v-if="body.header">{{ body.header }}</p>

    <w-checkbox
      type="checkbox"
      v-model="none"
      id="none"
      value="true"
      label="None"
      v-if="body.allowempty"
    />

    <w-checkbox
      type="checkbox"
      v-model="answers"
      v-for="(list, i) in body.answerlist"
      :key="list.name + i"
      :id="list.id"
      :value="list.id"
      :label="list.name"
      :description="list.description"
    >
      <template v-if="list.image" v-slot:image>
        <div class="img-holder">
          <img :src="list.image" :alt="list.name" />
        </div>
      </template>
    </w-checkbox>

    <w-checkbox
      type="checkbox"
      v-model="activateOthers"
      id="others"
      value="true"
      label="Other"
      v-if="body.hasothersarray"
    />

    <Array
      v-if="activateOthers"
      :body="arrayBody"
      @submitAnswer="checkAnswer"
      @alert="alert"
      :othersField="others"
    />
    <b-button
      variant="info"
      block
      pill
      @click="checkAnswer"
      v-if="!activateOthers"
      :disabled="disabled"
      >Continue</b-button
    >
  </div>
</template>

<script>
import Array from "@/components/onboarding/questions/Array";

export default {
  props: {
    body: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Array,
  },
  data() {
    return {
      answers: [],
      others: [],
      activateOthers: false,
      arrayBody: {},
      none: false,
    };
  },
  methods: {
    alert(msg) {
      this.$emit("alert", msg);
    },
    checkAnswer(id = null, others = null, body = null) {
      if (this.activateOthers) {
        this.others = others;
      }

      if (this.body.allowempty) {
        // * If "none" is not selected, and no other answers and others have not been supplied
        if (!this.none && this.answers.length == 0 && this.others.length == 0) {
          this.$emit("alert", "Please select an option.");
          return;
        }
      }

      this.$emit(
        "submitAnswer",
        this.body.id,
        this.answers,
        this.body,
        null,
        this.others
      );
    },
  },
  watch: {
    none() {
      if (this.none) {
        this.answers = [];
        this.activateOthers = false;
      }
    },
    answers() {
      if (this.answers.length > 0) {
        this.none = false;
      }
    },
    activateOthers() {
      if (this.activateOthers) {
        this.none = false;
      }
    },
  },
  mounted() {
    if (this.body.hasOwnProperty("answer")) {
      this.answers = this.body.answer;
      if (this.body.answer.length == 0 && !this.body.hasOwnProperty("others")) {
        this.none = true;
        return;
      }
      if (this.body.hasOwnProperty("others")) {
        this.activateOthers = true;
        this.others = this.body.others;
      }
    }
  },
};
</script>
