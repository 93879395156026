var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "symptoms-icon" }, [
    _c("img", {
      staticClass: "symptoms-icon__img",
      attrs: { src: _vm.src, alt: _vm.symptom.name },
    }),
    _c("span", { staticClass: "symptoms-icon__name" }, [
      _vm._v(" " + _vm._s(_vm.symptom.name) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }