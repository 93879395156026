var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "pl-3" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v("What symptoms would you like to treat?"),
      ]),
      _c(
        "b-alert",
        { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
        [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
      ),
      _c(
        "b-col",
        { staticClass: "fs-exclude mb-2 pl-0 pr-0", attrs: { cols: "12" } },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "symptoms" },
                _vm._l(15, function (i) {
                  return _c("BSkeleton", {
                    key: i,
                    staticClass: "tw-inline tw-gap-4",
                  })
                }),
                1
              )
            : _c(
                "ul",
                { staticClass: "list-unstyled symptoms" },
                _vm._l(_vm.symptoms, function (symptom, i) {
                  return _c("li", { key: i }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selected,
                          expression: "selected",
                        },
                      ],
                      attrs: { type: "checkbox", id: "checkbox-" + i },
                      domProps: {
                        value: symptom.id,
                        checked: Array.isArray(_vm.selected)
                          ? _vm._i(_vm.selected, symptom.id) > -1
                          : _vm.selected,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.selected,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = symptom.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selected = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selected = $$c
                          }
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: "checkbox-" + i } },
                      [
                        _c("SymptomsLabel", { attrs: { symptom: symptom } }),
                        0
                          ? _c("div", { staticClass: "symptoms-icon" }, [
                              _c("img", {
                                staticClass: "symptoms-icon__img",
                                attrs: {
                                  src: _vm.preloadedIcons[symptom.id],
                                  alt: symptom.name,
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "symptoms-icon__name" },
                                [_vm._v(" " + _vm._s(symptom.name) + " ")]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
        ]
      ),
      _c(
        "b-col",
        { staticClass: "pl-0", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "btn mb-3",
              attrs: { pill: "", variant: "info", disabled: _vm.disabled },
              on: { click: _vm.submitSymptoms },
            },
            [_vm._v(" Continue ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }