/**
import analyticsMixin from "@/mixins/analytics";
mixins: [analyticsMixin],
 */

export default {
  methods: {
    async $_analytics_track(event, properties) {
      console.log(`Tracked "${event}"`);

      const [
        deviceDetailsForContext,
        deviceDetails,
        everflowDetails,
        voluumDetails,
      ] = await Promise.all([
        this.$store.dispatch("getDeviceDetailsForContext"),
        this.$store.dispatch("geDeviceDetails"),
        this.$store.dispatch("getEverflowDetails"),
        this.$store.dispatch("getVoluumDetails"),
      ]);

      window.analytics.track(
        event,
        {
          ...properties,
          ...deviceDetailsForContext,
          ...voluumDetails,
        },
        {
          referrer: everflowDetails,
          context: deviceDetails,
        }
      );
    },
  },
};
