<template>
  <div class="dynamic-array">
    <h2 class="question-title">{{ body.question }}</h2>
    <!-- First allergy -->
    <b-form-input
      type="text"
      v-model="answers[0].allergy"
      placeholder="Input your allergy here"
      class="onboarding-input-lg"
    />
    <b-form-textarea
      class="mt-3 onboarding-textarea"
      rows="4"
      v-model="answers[0].effect"
      v-if="answers[0].allergy !== ''"
      placeholder="What happens when you take the drug above?"
    />
    <!-- Subsequent allergies -->
    <div class="mb-3" v-for="(answer, index) in answers" :key="index">
      <b-icon
        style="float: right; cursor: pointer"
        class="mb-3"
        icon="x-circle-fill"
        v-if="index"
        @click="$delete(answers, index)"
      ></b-icon>
      <b-form-input
        type="text"
        v-model="answers[index].allergy"
        v-if="index > 0"
        placeholder="Input your allergy here"
        class="onboarding-input-lg"
      />
      <b-form-textarea
        class="mt-3 onboarding-textarea"
        rows="4"
        v-model="answers[index].effect"
        v-if="index > 0"
        placeholder="What happens when you take the drug above?"
      />
    </div>
    <u>
      <a
        class="text-info"
        href="javascript:void(0)"
        v-if="activateControls"
        @click="addAnswer"
        >+ Add another allergy
      </a>
    </u>
    <b-button
      pill
      block
      class="mt-4"
      variant="info"
      @click="checkAnswer"
      v-if="activateControls"
      :disabled="disabled"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answers: [{ allergy: "", effect: "" }],
    };
  },
  computed: {
    activateControls() {
      return this.answers.length > 0 && this.answers[0].allergy !== "";
    },
  },
  methods: {
    addAnswer() {
      this.answers.push({ allergy: "", effect: "" });
    },
    checkAnswer() {
      this.$emit("submitAnswer", this.body.id, this.answers, this.body);
    },
  },
  mounted() {
    if (this.body.hasOwnProperty("answer")) {
      this.answers = this.body.answer;
    }
  },
};
</script>

<style lang="scss" scoped>
.form-control[type="text"] {
  border: 2px solid #e0e0e0;
  border-radius: 30px;

  &:focus {
    border-color: $primary;
  }
}

textarea.form-control {
  background-color: #fafafa;
  border: 0;

  &:focus {
    background-color: #fafafa;
  }
}
</style>
