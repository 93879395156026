<template>
  <Array
    :body="questionBody"
    :disabled="disabled"
    ref="arrayComponent"
    @submitAnswer="submitAnswer"
    @alert="showAlert"
  />
</template>

<script>
//  * This entire component is just a wrapper to allow for future changes.
import { OnboardingService } from "@/services/onboarding.service";
import Array from "@/components/onboarding/questions/Array";

export default {
  components: {
    Array,
  },
  data() {
    return {
      questionBody: {
        question: "What other menopause symptoms do you experience?",
        placeholder: "Enter here...",
      },
      disabled: false,
    };
  },
  methods: {
    submitAnswer(id = null, answer) {
      this.$emit("submitAnswer", answer, true);
    },
    showAlert(msg) {
      this.$emit("alert", msg);
    },
  },
  // * Optimize this.
  created() {
    const data = OnboardingService.getPatientOnboarding().then((data) => {
      if (data.data.hasOwnProperty("othersymptom")) {
        this.$refs.arrayComponent.answer = data.data.othersymptom;
      }
    });
  },
};
</script>
