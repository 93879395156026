var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("OnboardingProgress", {
        attrs: { hide: _vm.hideBackButton, progress: _vm.progress },
        on: { back: _vm.prev },
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: { hide: _vm.hideBackButton, step: "2", title: _vm.title },
            on: { back: _vm.prev },
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: {
                cols: "12",
                lg: _vm.questionType == "symptoms" ? 6 : 5,
                xl: "4",
                xxl: "4",
              },
            },
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v(" Intake ")]
                    ),
                  ]),
                  _c("b-col", [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "bold d-letter-spacing onboarding-title text-muted text-right",
                      },
                      [_vm._v(" Onboarding Completion ")]
                    ),
                  ]),
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: {
                  value: _vm.progress[1].value,
                  max: 2.9,
                  variant: "purple",
                  height: "5px",
                },
              }),
              _c("DiscountBanner", {
                attrs: {
                  show: _vm.showCouponBanner,
                  couponid: _vm.coupon.couponid,
                },
              }),
              _vm.questionType == "symptoms"
                ? _c("Symptoms", {
                    attrs: {
                      selectedSymptoms: _vm.symptoms,
                      userid: _vm.analyticsId,
                      disabled: _vm.buttonDisabled,
                      firstname: _vm.firstname,
                      lastname: _vm.lastname,
                      onboardingflow: _vm.onboardingflow,
                    },
                    on: { submitSymptoms: _vm.submitSymptoms },
                  })
                : _vm._e(),
              _vm.questionType == "othersymptom"
                ? _c("OtherSymptoms", {
                    on: { submitAnswer: _vm.submitSymptoms },
                  })
                : _vm._e(),
              _vm.questionType == "single" && _vm.questionBody.type != "modal"
                ? _c("Single", {
                    ref: "single",
                    class: {
                      "treatment-type": _vm.step == 2.77,
                      "fs-mask": _vm.isMasked,
                    },
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: {
                      submitAnswer: _vm.submitAnswer,
                      alert: _vm.showAlert,
                    },
                  })
                : _vm._e(),
              _vm.questionType == "nothing"
                ? _c("Nothing", {
                    class: { "fs-mask": _vm.isMasked },
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.questionType == "multiple"
                ? _c("Multiple", {
                    class: { "fs-mask": _vm.isMasked },
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: {
                      submitAnswer: _vm.submitAnswer,
                      alert: _vm.showAlert,
                    },
                  })
                : _vm._e(),
              _vm.questionType == "text"
                ? _c("TextQuestion", {
                    class: { "fs-mask": _vm.isMasked },
                    attrs: {
                      heading: "",
                      "text-height": "70px",
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.questionType == "array"
                ? _c("Array", {
                    class: { "fs-mask": _vm.isMasked },
                    attrs: {
                      heading: "",
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.questionType == "dynamicarray"
                ? _c("DynamicArray", {
                    class: { "fs-mask": _vm.isMasked },
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.questionType == "bmi"
                ? _c("BMI", {
                    attrs: {
                      bmi: _vm.BMI,
                      userid: _vm.analyticsId,
                      disabled: _vm.buttonDisabled,
                      onboardingflow: _vm.onboardingflow,
                      firstname: _vm.firstname,
                      lastname: _vm.lastname,
                    },
                    on: { submitAnswer: _vm.submitBMI, alert: _vm.showAlert },
                  })
                : _vm._e(),
              _vm.questionType == "single" && _vm.questionBody.type == "modal"
                ? _c("Modal", {
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.questionType == "over60"
                ? _c("Nothing", {
                    attrs: {
                      body: _vm.questionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submit60 },
                  })
                : _vm._e(),
              _vm.composite && _vm.secondQuestionType == "single"
                ? _c("Single", {
                    staticClass: "pt-4 follow-up-questions",
                    attrs: {
                      body: _vm.secondQuestionBody,
                      disabled: _vm.buttonDisabled,
                    },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.composite && _vm.secondQuestionType == "text"
                ? _c("TextQuestion", {
                    staticClass: "pt-4 follow-up-questions",
                    attrs: {
                      body: _vm.secondQuestionBody,
                      composite: _vm.composite,
                      disabled: _vm.buttonDisabled,
                    },
                    on: {
                      alert: _vm.showAlert,
                      submitAnswer: _vm.submitAnswer,
                    },
                  })
                : _vm._e(),
              _vm.composite && _vm.secondQuestionType == "array"
                ? _c("Array", {
                    staticClass: "pt-4 follow-up-questions",
                    attrs: {
                      body: _vm.secondQuestionBody,
                      composite: _vm.composite,
                      disabled: _vm.buttonDisabled,
                    },
                    on: {
                      alert: _vm.showAlert,
                      submitAnswer: _vm.submitAnswer,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", lg: "3", xl: "4", xxl: "4" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }