<template>
  <b-row align-v="stretch" class="nothing">
    <b-col align-self="center">
      <h2 class="question-title with-subtext">
        {{ body.title }}
      </h2>
      <p class="sub-text mb-0" v-if="body.description">
        {{ body.description }}
      </p>
      <div class="image-holder" v-if="body.image">
        <img :src="body.image" alt="" />
      </div>

      <b-button
        pill
        style="margin-top: 1.6875rem"
        variant="info"
        @click="submitAnswer"
        :disabled="disabled"
        >Continue</b-button
      >
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitAnswer() {
      this.$emit("submitAnswer", this.body.id, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.image-holder {
  height: 350px;
  margin-bottom: 1rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
